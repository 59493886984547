import React from 'react';

import {Box, BoxProps, FormControl, FormLabel, Tooltip} from '@chakra-ui/react';

import {SelectControl} from 'formik-chakra-ui';

import {InfoIcon} from '../assets/icons';

interface IOption {
  id?: string;
  name: string;
}

interface ISelectInputProps extends BoxProps {
  title: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  tooltip: string;
  options: IOption[];
  defaultValue?: string;
  isDisabled?: boolean;
}

export function SelectInput(props: ISelectInputProps) {
  const {
    title,
    placeholder,
    required,
    name,
    tooltip,
    options,
    defaultValue,
    isDisabled,
  } = props;
  return (
    <Box {...props}>
      <FormControl isRequired={required}>
        <FormLabel fontWeight='bold' alignItems='center' display='flex'>
          <Tooltip label={tooltip} placement='right-start'>
            <InfoIcon w={17} h={17} mr={3} />
          </Tooltip>
          {title}
        </FormLabel>
        <SelectControl
          name={name}
          selectProps={{
            placeholder: placeholder,
            size: 'sm',
            defaultValue: defaultValue,
            isDisabled: isDisabled,
          }}>
          {options.map(op => (
            <option key={op.id} value={op.id}>
              {op.name}
            </option>
          ))}
        </SelectControl>
      </FormControl>
    </Box>
  );
}
